<template>
	<div class="full-height pa-10 flex-column " v-if="false">
		<div class="justify-space-between full-height">
			<div class="flex-1 pa-10 bg-white mr-10 position-relative">
				<h6 class="justify-space-between position-relative"  style="z-index: 999">주간 매출 <div class="box pa-5-10 radius-10 size-px-12 bg-white">준비중입니다</div></h6>
				<GChart
					v-if="chartData.length > 0"
					type="LineChart"

					@ready="onChartReady"
				/>
				<div
					style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: black; opacity: 0.5;"
				></div>
			</div>
			<div  class="flex-1 pa-10 bg-white position-relative">
				<h6 class="justify-space-between position-relative"   style="z-index: 999">연 매출 <div class="box pa-5-10 radius-10 size-px-12 bg-white">준비중입니다</div></h6>
				<GChart
					v-if="chartData2.length > 0"
					type="ColumnChart"

					@ready="onChartReady2"
				/>

				<div
					style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: black; opacity: 0.5;"
				></div>
			</div>
		</div>
		<div  class="mt-10 justify-space-between full-height">
			<div class="flex-1 pa-10 bg-white mr-10 position-relative">
				<h6 class="justify-space-between position-relative"  style="z-index: 999">정산 예정 <div class="box pa-5-10 radius-10 size-px-12 bg-white">준비중입니다</div></h6>

				<table class="mt-10 table th-left td-right">
					<col width="120px" />
					<col width="auth" />
					<tbody>
					<tr>
						<th>매출 건수</th>
						<td>120건</td>
					</tr>
					<tr>
						<th>매출 금액</th>
						<td>12,350,000원</td>
					</tr>
					<tr>
						<th>정산 예정 금액</th>
						<td>120,300원</td>
					</tr>
					</tbody>
				</table>

				<div
					style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: black; opacity: 0.5;"
				></div>
			</div>
			<div  class="flex-1 pa-10 bg-white mr-10 position-relative">
				<h6 class="justify-space-between position-relative"  style="z-index: 999">주간 매출 <div class="box pa-5-10 radius-10 size-px-12 bg-white">준비중입니다</div></h6>

				<div
					style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: black; opacity: 0.5;"
				></div>
			</div>
			<div  class="flex-1 pa-10 bg-white position-relative">

			</div>
		</div>
	</div>
</template>

<script>

	import { GChart } from 'vue-google-charts'

	export default{
		name: 'Main'
		,components: { GChart }
		,data: function(){
			return {
				program: {
					top: true
				}
				,chartData: [
					['일자', '금액']
					,[1, 2]
				]
				,chartData2: [
					['일자', '금액']
					,[1, 2]
				]
				,chart: ''
				,chart2: ''
				,chartOptions: {
					chart: {
						title: "Company Performance",
						subtitle: "Sales, Expenses, and Profit: 2014-2017"
					}
					,width: ''
					,height: '350'
					,padding: '0'
					,vAxis: {
					}
				}
				,items_notice: [
					{ title:'공지사항', date: '2021.11.01', idx:1}
					,{ title:'공지사항', date: '2021.11.02', idx:2}
					,{ title:'공지사항', date: '2021.11.03', idx:3}
					,{ title:'공지사항', date: '2021.11.04', idx:4}
					,{ title:'공지사항', date: '2021.11.05', idx:5}
				]
				,summary: {

				}
			}
		}
		,computed: {
			notice_list: function(){
				return this.items_notice.filter(function(item){
					return item
				})
			}
		}
		,methods: {

			onChartReady: async function(chart, google){
				this.chart = chart
				this.google = google

				await this.getChartData()
			}
			,onChartReady2: async function(chart, google){
				this.chart2 = chart
				this.google = google

				await this.getChartData2()
			}
			,getChartData: async function(){
				this.chartData = []

				let today = this.date.getToday('-')

				this.chartData = [
					['일자', '금액']
					,[this.date.getLastDate(today, 5, '-'), 10]
					,[this.date.getLastDate(today, 4, '-'), 20]
					,[this.date.getLastDate(today, 3, '-'), 50]
					,[this.date.getLastDate(today, 2, '-'), 4]
					,[this.date.getLastDate(today, 1, '-'), 5]
					,[today, 60]
				]

				if(this.google.visualization) {
					let data = this.google.visualization.arrayToDataTable(this.chartData, false);

					this.chart.draw(data, this.chartOptions)
				}
			}

			,getChartData2: async function(){

				this.chartData2 = [
					['월', '금액']
					,[1, 10]
					,[2, 100]
					,[3, 200]
					,[4, 100]
					,[5, 123]
					,[6, 100]
					,[7, 400]
					,[8, 100]
					,[9, 1000]
					,[10, 2000]
					,[11, 500]
					,[12, 300]
				]

				if(this.google.visualization) {
					let data = this.google.visualization.arrayToDataTable(this.chartData2, false);

					this.chart2.draw(data, this.chartOptions)
				}
			}
			,toNotice: function(item){
				this.$layout.push( { name: 'NoticeDetail', params: {idx: item.idx }})
			}
			,getNotice: async function(){
				try{
					this.$layout.onLoading()
					let result = await this.$Axios({
						method: 'get'
						, url: 'notice'
						, data: {
							page: 1
							, size: 10
							, viewGroup: 0
						}
					})

					if(result.success){
						this.items_notice = result.data.tableList.data
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}
			,getData: async function(){
				try{
					this.$layout.onLoading()
					const result = await this.$Axios({
						method: 'get'
						,url: 'payment'
						,data: {
							sDate: this.date.getLastDate(this.date.getToday('-'), 7, '-')
							,eDate: this.date.getToday('-')
							,branchIdx: this.user.branchIdx
							,distributorIdx: this.user.distributorIdx
							,agencyIdx: this.user.agencyIdx
							,resellerIdx: this.user.resellerIdx
							,page: 1
							,size: 10
						}
					})

					if(result.success){
						this.summary = result.data.statistics
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}
		}
		,created: function(){
            console.log('1111111111111111111111111111111111111111111111')
            this.$layout = this.$root.$children[0].$children[0]
            this.user = this.$layout.user
			this.$layout.push( { name: 'SettlementMypage'})
		}
	}
	
</script>